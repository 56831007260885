

.nav {
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  @include rem-fallback(font-size, 20px);
  
  @include media-query($on-tablet) {
    position: static;
  }
  
  @include media-query($on-tablet){
    width: 100vw;
    @include rem-fallback(margin-left, -30px);
    border-top: 1px solid $grey-color-light;
  }
  
  @include media-query($on-palm){
    position: absolute;
    width: auto;
    margin-left: 0;
    border-top: none;
  }
  
  ul {
    height: 100%;
  }
  
  .current-section {
    background-color: $grey-color-dark;
    color: $white;
    padding: 0;
    @include rem-fallback(min-width, 100px);
    flex-grow: 0;
    
    @include media-query($on-palm) {
      background-color: $white;
      color: $black;
    }

    a {
      text-align: center;
    }
  }
  
  
  li {
    @include rem-fallback(padding-left, $half-spacing);
    @include rem-fallback(padding-right, $half-spacing);
    
    &:first-child {
      padding: 0;
      flex-grow: 0;
    }
  }
  
  a {
    display: block;
    @include rem-fallback(line-height, 100px);
  }
  
  .home-link {
    display: none;
    
    @include media-query($on-palm) {
      display: block;
    }
  }
  
  label {
    display: none;
  }
}

.nav-trigger {
  display: none;
}

.nav-list {
  display: flex;
  margin-left: 0;
  
  @include media-query($on-tablet){
    @include rem-fallback(padding-left, $spacing-unit);
    @include rem-fallback(padding-right, $spacing-unit);
  }
  
  @include media-query($on-palm){
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
    z-index:1000;
  }
}
.nav-item {
  display: block;
  list-style-type: none;
  flex-grow: 1;
  
  @include media-query($on-tablet) {
    text-align: center;
  }
  
  @include media-query($on-palm){
    margin-left: 0;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
}

.home-link {
  display: none;
  
  @include media-query($on-palm) {
    display: block;
  }
}


/************** Mobile Styles ******************************/


@include media-query($on-palm) {
  
  .hamburger,
  .close {
    @include rem-fallback(line-height, 100px);
  }

  .close {
    display: none;
  }

  input:checked ~ .nav-list {
    display: block;
    height: 100vh;
    width: 100vw;
    position: fixed;
    @include rem-fallback(top, -25px);
    background-color: $background-color;
    left: 0;
    overflow: hidden;
  }
  
  
  input:checked ~ label[for="nav-trigger"] {
    position: fixed;
    @include rem-fallback(right, 15px);
    z-index: 1001; // required to keep this visible on top of  the z-index: 1000 on .category-filter on the blog page
    text-align: right;

    .close {
      display: block;
    }

    .hamburger {
      display: none;
    }
  }

  .nav-list {
    display: none;
    @include rem-fallback(margin-top, 25px);
    @include rem-fallback(padding-top, 100px);
    border-top: 5px solid $grey-color-dark;
    text-align: center;
    box-sizing: border-box;

  }

  .nav-item {
    box-sizing: border-box;
    width: 100vw;
    border-top: 3px solid $grey-color-light;

    a {
      display: block;
      padding: 25px 0;
      @include rem-fallback(line-height, $double-spacing);
    }


    &:last-of-type {
      border-bottom: 3px solid $grey-color-light;
    }

    &:hover {
      background-color: $grey-color-light;
    }

  }


  .nav {
    label {
      display: block;
      
      &:hover {
        cursor: pointer;
      }
    }
  }
}
