.about {
  
  .post-content {
    display: flex;
    align-items: center;
    
    .text {
      margin-left: $spacing-unit;
    }

    @include media-query($on-palm) {
      display: block;
      
      img {
        display: block;
        margin: 0 auto;
        @include rem-fallback(margin-bottom, $half-spacing)
      }
      
      .text {
        margin-left: 0;
      }
    }
  }
}

.wrapper {
  &.home {
    max-width: 100%;
    padding: 0;
    
    header,
    ul,
    .post-list,
//     .post-content,
//     div,
//     article,
//     section
    .more {
      max-width: -webkit-calc(#{$content-width} - #{$double-spacing});
      max-width:         calc(#{$content-width} - #{$double-spacing});
      margin-right: auto;
      margin-left: auto;
      @include rem-fallback(margin-bottom, $spacing-unit);
      @extend %clearfix;

      @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
      }

      @include media-query($on-palm) {
        padding: 0;
      }
    }
  }
}

.home {
  
  section {
    &:last-child {
      .more {
        margin-bottom: 0;
      }
    }
  }
  
  &.post,
  &.art,
  &.work {
    border-top: 1px solid $grey-color-light;
    padding-top: 30px;
    
    .section-title {
      @include media-query($on-palm) {
        text-align: center;
      }
    }
    
    .certifications {
      .certification-title {
        @include rem-fallback(margin-top, $quarter-spacing);
      }
      
      .logo {
        @include rem-fallback(height, 100px);
      }
    }
  }
  
  .post-list {
    @include rem-fallback(margin-top, $spacing-unit);
    @include rem-fallback(margin-bottom, $spacing-unit);
    
    .post {
      margin-bottom: 0;
    }
  }
  
  // These home page styles are unnecessarily cumbersome
  // All of these items have individual margins and widths 
  // At some point, I need to go through and put each section into a div so that I don't need to do this 
  // Probably be best to do this with a .page-width class that I can use all over the place
  .section-title {
    margin-left: auto;
    margin-right: auto;
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));

    @include media-query($on-laptop) {
      max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
      max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    }
  }
  
  .art {
    @include rem-fallback(margin-bottom, $spacing-unit);
    
    @include media-query($on-laptop) {
      margin-bottom: 0;
    }
    
    .post-list {
      display: flex;
      align-items: center;
      
      @include media-query($on-palm){
        display: block;
      }
      
      .art-preview {
        height: 25vh;
        width: 24%;
        margin-right: 1%;
        background-color: $grey-color-light;
        
        @include media-query($on-tablet){
          width: 33%;
          
          &.art-preview-4 {
            display: none;
          }
          
          @include media-query($on-palm){
            width: auto;
            max-width: 100%;
            margin: 0;
            @include rem-fallback(margin-bottom, $half-spacing);
            height: 60vh;
            
            &.art-preview-3 {
              display: none;
            }
          }
        }
        
        &:last-child {
          margin-right: 0;
        }
      }
      
      a {
        display: block;
        height: 100%;
      }
          
      .thumbnail {
        height: calc(100% - #{$double-spacing});
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
      }
      
      .title {
        display: block;
        text-align: center;
        margin: 0;
        color: $text-color;
        background-color: $grey-color-light;
        height: $double-spacing;
        line-height: $double-spacing;
        vertical-align: middle;
        @include rem-fallback(font-size, 18px);
        
        a {
          color: $text-color;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}