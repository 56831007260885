.book-cover {
	@include rem-fallback(margin-bottom, $spacing-unit);
}

.book-title-author {
	h2, h3, .h3, p {
		margin: 0;
	}
	
	.small-font {
		position: relative;
		@include rem-fallback(top, -15px);
	}
	
	.book-author {
		position: relative;
		@include rem-fallback(top, -22px);
		
	}
}

.headline {
	@include rem-fallback(margin-bottom, $spacing-unit);
	p {
		margin: 0;
	}
}

.purchase {
	@include rem-fallback(margin-top, $spacing-unit);
	
}