.blog-post {
  
  .post-title {
    &.has-subtitle {
      margin-bottom: 0;
    }
  }
    
  .subtitle {
    @include rem-fallback(font-size, $base-font-size * 1.25);
  }
  
  img {
    display: block;
    max-width: 75%;
    max-height: 60vh;
    margin: 0 auto;
    @include rem-fallback(margin-top, $spacing-unit);
    @include rem-fallback(margin-bottom, $spacing-unit);

    @include media-query($on-tablet){
      max-width: 100%;
      max-height: 80vh;
    }
    
    &.featured {
      margin-top: unset;
    }
  }
}