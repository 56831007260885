.license-blurb {
	display: flex;
	
	p {
		align-self: center;
	}
	
	.license-icon {
		@include rem-fallback(font-size, $base-font-size * 1.25);
		@include rem-fallback(margin-right, $half-spacing);
		white-space: nowrap;
	}
	
	@include media-query ($on-palm) {
		display: block;
		
		.license-icon {
			margin-bottom: 0;
		}
	}
}