.contact {
  
  h3 {
    margin-bottom: 0;
    @include rem-fallback(margin-top, $double-spacing);
    @include rem-fallback(font-size, 25px);
    
    &:first-of-type {
      margin-top: 0;
    }
  }
  
  input,
  textarea  {
    box-sizing: border-box;
    width: 100%;
    @include rem-fallback(padding-left, 10px);
    @include rem-fallback(padding-right, 10px);
    @include rem-fallback-border(1, solid, $grey-color-dark);
    @include px-font-size($base-font-size);
    
    &:focus {
      outline-color: $brand-color;
    }
  }
  
  textarea {
    @include rem-fallback(padding, 10px);
  }
  
  input {
    height: 45px;
  }
  
  textarea {
    @include rem-fallback(margin-bottom, $spacing-unit);
  }
}