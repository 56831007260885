
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
  margin-top: auto; // along with display-flex on the body, this keeps the footer at the bottom of the page when there's not much content e.g., blurbs
  
  h3 {
    @include rem-fallback(margin-bottom, $quarter-spacing);
  }
  
  a {
    text-decoration: none;
    color: inherit;
    
    &:hover {
      text-decoration: none;
      color: $black;
    }
  }
  
  ul {
    margin-left: 0;
  }
  
  li {
    list-style-type: none;
  }
  
  
  .wrapper {
  
    @include media-query($on-laptop){
      padding-left: $spacing-unit * 2;
      padding-right: $spacing-unit * 2;
    }

    @include media-query($on-tablet){
      padding-left: $spacing-unit;
      padding-right: $spacing-unit;
    }

    @include media-query($on-palm){
      padding-left: $spacing-unit / 2;
      padding-right: $spacing-unit / 2;
    }
  }
}

.footer-heading {
  @include relative-font-size(1.125);
  @include rem-fallback(margin-bottom, $half-spacing);
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-col-wrapper,
.site-description {
  @include relative-font-size(0.9375);
  color: $grey-color-dark;
}

.footer-col {
  @include rem-fallback(margin-bottom, $half-spacing);
  width: 25%;
  
  @include media-query($on-tablet){
    width: 50%;
  }
  
  @include media-query($tiny) {
    width: 100%;
  }
}

