.syndications {
	border-top: 1px solid $black;
	border-bottom: 1px solid $black;
	@include rem-fallback(padding, $double-spacing);
	@include rem-fallback(margin-top, $double-spacing);
	background-color: $grey-color-extra-light;
	
	@include media-query ($on-tablet) {
		@include rem-fallback(padding-left, $spacing-unit);
		@include rem-fallback(padding-right, $spacing-unit);
	}
	.syndication-notice {
		@include rem-fallback(margin-bottom, $spacing-unit);
	}
	
	ul {
		list-style-type: none;
		margin: 0;
		display: flex;
		
		@include media-query ($on-palm) {
			display: block;
		}
	}
	
	.syndication {
		display: flex;
		@include rem-fallback(margin-bottom, $spacing-unit);
		@include rem-fallback(padding-right, $spacing-unit);
		width: 50%;
		
		h5, p {
			margin: 0;
			padding: 0;
		}
		
		.syndication-icon {
			@include rem-fallback(margin-right, $half-spacing);
			@include rem-fallback(font-size, $huge-font-size);
		}
	}
}