.spoiler {
	visibility: hidden;
	opacity: 0;
	@include rem-fallback(max-height, 160px);
	// transition: all $slow-transition-speed ease-in-out;
	// transition: all 15s ease-in-out;
}

.contains-spoilers {
	@include rem-fallback(height, 160px);
	@include rem-fallback(margin-bottom, $spacing-unit);
	position: relative;
}

.reveal-container,
.overlay-box {
	position: absolute;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
	
}

.overlay-box {
	// @include rem-fallback(top, -100px);
	z-index: -1;
	height: 100%;
}

.overlay-line {
	@include rem-fallback(height, 16px);
	@include rem-fallback(margin-bottom, 4px);
	width: 100%;
	background-color: $gray-color-extra-light;
	background: radial-gradient($gray-color-extra-light 30%, $white);
}

.reveal-container {
	text-align: center;
	
	.spoiler-warning {
		background: radial-gradient($white 10%, transparent);
		@include rem-fallback(padding, $quarter-spacing);
	}
	
	.button {
		display: inline-block;
		@include rem-fallback(font-size, $small-font-size);
		@include rem-fallback(padding, $quarter-spacing);
	}
}

.revealed-spoiler {
	transition: all $slow-transition-speed ease-in-out;
	
	&.contains-spoilers {
		height: auto;
		transition: all $slow-transition-speed ease-in-out;
		margin-bottom: unset;
		
	}
	
	.spoiler {
		visibility: visible;
		opacity: 1;
		@include rem-fallback(max-height, 1000px);
		transition: all $slow-transition-speed ease-in-out;
	}
	
	.overlay-box {
		height: unset;
	}

	.reveal-container,
	.overlay-box {
		opacity: 0;
		visibility: hidden;
		transition: all $slow-transition-speed ease-in-out;
	}
	
	.reveal-container {
		// top: 0;
			
		.spoiler-warning,
		.reveal-button {
			opacity: 0;
			transition: all $slow-transition-speed ease-in-out;
			
		}
	}
	
}