.blog-index {
  
  li {
    clear: both;
  }
  
  .post-title {
    // @include rem-fallback(margin-bottom, $half-spacing);
    margin-bottom: 0;
    
    @include media-query($on-palm) {
      text-align: center;
      @include rem-fallback(margin-top, $spacing-unit);
    }
  }
  .category, .tags {
    @include media-query($on-palm) {
      text-align: center;
    }
  }
  .post-meta {
    @include media-query($on-palm){
      @include rem-fallback(margin-top, $half-spacing);
      margin-bottom: 0;
    }
  }
  
  .snippet-image-container {
    overflow: auto;
    
    &.portrait {
      display: flex;
      align-items: center;
      
      @include media-query($on-palm) {
        display: block;
      }
    }
    
    &.landscape {
      .featured-image {
        float: left; 
        @include rem-fallback(margin-bottom, $half-spacing);
        
        
        @include media-query($on-palm) {
          float: none;
          margin-bottom: 0;
        }
        
      }
    }
    
    @include media-query($on-palm) {
      display: block;
    }
  }
  
  .featured-image {
    display: block;
    text-align: center;
    max-width: 25%;
    max-height: 100%;
    @include rem-fallback(margin-right, $spacing-unit);
    
    @include media-query($on-laptop) {
      max-width: 30%;
    }
    
    @include media-query($on-tablet) {
      max-width: 35%;
    }
    
    @include media-query($on-palm) {
      max-width: 100%;
    }
    
    img {
      max-height: 100%;
      max-width: 100%;

      &.book-cover {
        @include rem-fallback(max-height, 300px);
        max-width: unset;
        
        @include media-query ($on-palm) {
          height: inherit;
        }
      }
    }
    
    &.landscape {
      @include media-query($on-palm) {
        max-height: 20vh;
        
        img {
          max-height: 20vh;
        }
      }
    }
    
    &.portrait {
      
      @include media-query($on-palm){
        height: 30vh;
      }
    }
    
    @include media-query($on-palm) {
      margin-right: auto;
      margin-left: auto;
    }
  }
  
  .snippet-container {
    margin-left: 0;
    max-width: 100%;
  }
  
  .subtitle {
    @include rem-fallback(font-size,  $base-font-size);
    @include rem-fallback(margin-bottom, $half-spacing);
    
    &.series {
      @include rem-fallback(margin-bottom, $quarter-spacing);
    }
  }
}
