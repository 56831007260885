.display-flex {
  display: flex;
}

.text-align-center,
.center,
.centered {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.display-none {
  display: none;
}

.hidden {
  visibility: hidden;
  
  // Nesting prevents the need for an important tag
  // this could probably be fixed by cleaning up the way the post styles are defined
  &.hidden-by-filter {
    margin: 0;
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all $transition-speed ease-in-out;
    
    h3,
    h4,
    p {
      transition: all $transition-speed ease-in-out;
      margin: 0;
    }
  }
}

.small-font {
    font-size: $small-font-size;
}

.plain-link {
  color: $black;
  
  &:link,
  &:hover,
  &:visited,
  &:active {
    color: $text-color;
  }
  &:hover {
    text-decoration: none;
  }
}

.small {
  font-size: $small-font-size;
}

.half-spacing-margin-bottom {
  @include rem-fallback(margin-bottom, $half-spacing);
}

.quarter-spacing-margin-bottom {
  @include rem-fallback(margin-bottom, $quarter-spacing);
}