.work {
  
  .work-header {
    align-items: center;
  }
  
  section {
    @include rem-fallback(margin-top, $double-spacing);
    @include rem-fallback(padding-top, $double-spacing);
    border-top: 1px solid $grey-color-light;
    
    &:first-child {
      @include rem-fallback(padding-top, $spacing-unit);
      margin-top: 0;
      border-top: none;
      
    }
  }
  
  li {
    &:last-child {
      .logo-snippet-container {
        margin-bottom: 0;
      }
    }
  }
  
  .logo-snippet-container {
    display: flex;
    @include rem-fallback(margin-bottom, ($spacing-unit) * 2);
    @include rem-fallback(margin-top, $spacing-unit);
    
    @include media-query($on-palm){
      display: block; 
    }
  }
  
  .job-title,
  .certification-title,
  .company,
  .employment-dates {
    margin-bottom: 0;
    
    @include media-query($on-palm){
      text-align: center;
    }
  }
  
  .employment-dates {
    @include rem-fallback(margin-bottom, $half-spacing);
  }
  
  .logo {
    @include rem-fallback(width, 150px);

    @include media-query($on-tablet){
      @include rem-fallback(width, 125px);
    }

    @include media-query($on-palm){
      @include rem-fallback(width, 100px);
      margin: 0 auto;
      @include rem-fallback(margin-bottom, $half-spacing);
    }
  }
  
}

.snippet-container {
  @include rem-fallback(margin-left, $spacing-unit);
  width: 100vw;

  @include media-query($on-palm){
    width: 100%;
    margin-left: 0;
  }
}


////////////////////////  Certifications //////////////////////////////
.certification-header {
  text-align: center;
}

.certifications {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  h2 {
    text-align: center;
  }
  
  h3 {
    @include rem-fallback(font-size, 18px);
  }
  
  li {
    width: 100%;
    @include rem-fallback(min-width, 100px);
    @include rem-fallback(min-width, 200px);
    max-width:200px;
    @include rem-fallback(margin-right, 25px);
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  @include media-query($on-laptop) {
    margin-bottom: 0;
    
    li {
      width: 50%;
      margin: 0 auto;
      min-width: unset;
      max-width: unset;

      @include media-query ($on-laptop) {
        @include rem-fallback(margin-bottom, $spacing-unit);

        &:last-child {
          margin-right: auto;
        }
      }
    }
  }
  
  @include media-query($on-palm) {
    display: block;
    
    li {
      width: 100%;
    }
  }
  
  .logo {
    margin: auto;
    @include rem-fallback(height, 100px);
    text-align: center;
    width: auto;
    
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  
  .certification-title {
    text-align: center;
  }
  
  .post-meta {
    text-align: center;
    
    .expiration-date {
      font-style: italic;
    }
  }
}

////////////// Individual pages

.work-header {
  display: flex;
  
  @include media-query($on-palm) {
    display: block;
  }
  
  .title-company-dates {
    @include rem-fallback(margin-left, $spacing-unit);
    @include rem-fallback(margin-top, $quarter-spacing);
    
    @include media-query($on-palm) {
      margin-left: 0;
    }
    
    .post-meta {
      @include media-query($on-palm) {
        text-align: center;
      }
    }
  }
  
  .job-title {
    margin-bottom: 0;
  }
  
}

//////////////////////////// Trainings /////////////////////////////////

.training {
  @include rem-fallback(margin-top, $double-spacing);
  
  h3 {
    @include media-query($on-palm) {
      text-align: center;
    }
  }
  
  .post-meta {
    margin-bottom: 0;
  }
  
  &:first-child {
    margin-top: 0;
  }
}

.trainers {
  
  h4 {
    text-align: center;
  }
  ul {
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
    
    @include rem-fallback(max-width, $tiny);
    
    @include media-query($tiny) {
      display: block;
    }
  }
}

.trainer {
  list-style: none;
  text-align: center;
  margin-right: 5%;
  margin-left: 5%;
  
  &:first-child {
    margin-left: 0;
  }
  
  &:last-child {
    margin-right: 0;
  }
  
  @include media-query($tiny) {
    margin: 0;
  }
}

////////////////// Conferences ///////////////////////////

.conferences {
  
  .title-year {
    align-items: flex-end;
  }
  
  .logo {
    @include rem-fallback(max-width, 300px);
  }
  
  .post-meta {
    margin-bottom: 0;
  }
  
  li {
    @include rem-fallback(margin-top, $double-spacing);
    
    &:first-child {
      margin-top: 0;
    }
  }
}


.has-logo {
  display: flex;
  
  @include media-query($on-tablet) {
    display: block;
  }

  .logo {
    width: 100%;
    @include rem-fallback(margin-right, $spacing-unit);
    
    @include media-query($on-palm) {
      margin-right: auto;
    }
  }
  
  .meta-description-container {
    width: 100%;
  }

}
