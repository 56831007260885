/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-dark;
  @include rem-fallback(height, 100px);
  
  @include media-query($on-tablet) {
    @include rem-fallback(height, 200px);
  }
  
  @include media-query($on-palm) {
    @include rem-fallback(height, 100px);
  }
  .image-title {
    @include media-query($on-tablet) {
      text-align: center;
    }
    
    @include media-query($on-palm) {
      text-align: left;
    }
  }
  
  .site-title {
    @include rem-fallback(line-height, 100px);
    
    @include media-query($small-phone){
      @include rem-fallback(font-size, 25px);
    }
  }
  .header-image {
    border-radius: 50%; 
    @include rem-fallback(height, 70px); 
    @include rem-fallback(width, 70px); 
    vertical-align: top; 
    @include rem-fallback(padding-top, $half-spacing);
    @include rem-fallback(padding-bottom, $half-spacing);
    
//     @include media-query($small-phone) {
      
//       @include rem-fallback(height, 50px); 
//       @include rem-fallback(width, 50px); 
//       @include rem-fallback(padding-top, 25px);
//       @include rem-fallback(padding-bottom, 25px);
//     }
  }
  
  .wrapper {
    position: relative;
  }
  
  @include media-query($on-laptop){
    padding-left: $spacing-unit * 2;
    padding-right: $spacing-unit * 2;
  }
  
  @include media-query($on-tablet){
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;
  }
  
  @include media-query($on-palm){
    padding-left: $spacing-unit / 2;
    padding-right: $spacing-unit / 2;
  }
}

.site-title {
//   @include relative-font-size(1.625);
  @include rem-fallback(font-size, 30px);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  display: inline;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

#submenu {
  @include rem-fallback(margin-bottom, $spacing-unit);
  position: relative;
  @include rem-fallback(top, $negative-half-spacing);
    
  @include media-query($on-laptop){
    @include rem-fallback(padding-left, $double-spacing);
    @include rem-fallback(padding-right, $double-spacing);
    @include rem-fallback(top, $half-spacing);
  }
  
  @include media-query($on-tablet){
    @include rem-fallback(padding-left, $spacing-unit);
    @include rem-fallback(padding-right, $spacing-unit);
  }
  
  @include media-query($on-palm){
    @include rem-fallback(padding-left, $half-spacing);
    @include rem-fallback(padding-right, $half-spacing);
  }
  
  .category-filters {
    display: flex;
    justify-content: center;
    text-align: center;
    
    .button {
      flex-basis: 0;
      flex-grow: 1;
      border: none;
      border-right: 1px solid $white;
      border-top: 1px solid $grey-color-dark;
      border-bottom: 1px solid $grey-color-dark;
      @include rem-fallback(font-size, $small-font-size);
      background-color: $grey-color-dark;
      border-radius: 0;
      
      &:hover {
        background-color: $black;
        color: $white;
      }
      
      &:last-child {
        border-right: none;
      }
      
      &.active {
        background-color: $black;
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;
      }
      
      &.inactive {
        background-color: $white;
        color: $black;
        border-right: 1px solid $black;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        
        &:first-child {
          border-left: 1px solid black;
        }
      }
    }
  }
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  
  @include media-query($on-laptop) {
    padding: 0;
  }
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}


.post-list,
.category-filters {
  margin-left: 0;
  list-style: none;

  .post {
    margin-bottom: $double-spacing;
    transition: all $transition-speed ease-in-out;
    @include rem-fallback(max-height, 1000px); // This makes the transitions look smoother
  }
}

img {
  &.featured {
    max-width: 30vh;
    max-height: 40vh;
  }
}
.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: inline-block;
  @include relative-font-size(1.5);
}

.image-caption {
  text-align: center;
  @include rem-fallback(margin-top, -25px);
  @include rem-fallback(margin-bottom, $spacing-unit);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.title {
  @include relative-font-size(2);
  @include rem-fallback(letter-spacing, -1px);
}

.post-content {
  @include rem-fallback(margin-bottom, $spacing-unit);
}

button,
.button {
  @include rem-fallback(font-size, 18px);
  @include rem-fallback(padding, 10px);
  cursor: pointer;
  background-color: $black;
  color: $white;
  border: 1px solid $black;
  @include rem-fallback(border-radius, 5px);
  
  &:link,
  &:visited,
  &:active {
    color: $white;
  }
  
  &:hover {
    color: $text-color;
    text-decoration: none;
    background-color: $grey-color-extra-light;
  }
  
  &.white {
    background-color: $white;
    color: $black;
    
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

/**************** Font Awesome ********************************/

.svg-inline--fa {
  color: inherit;
}
