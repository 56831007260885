.blurb-container {
    background-color: $grey-color-super-light;
    border: 1px solid $grey-color;
    @include rem-fallback(border-radius, 10px);
    position: relative;
    
    .blurb {
        display: block;
        padding: $half-spacing;
        
        &:hover {
            text-decoration: none;
        }
    }
        
    
    &:hover {
        background-color: $grey-color-extra-light;
        
        .post-meta {
            color: $grey-color-dark;
        }
    }
    
    .blurb-text {
        color: $black;
    }
}

.single-blurb {
    &:hover {
        background-color: $gray-color-super-light;
        
        .post-meta {
            color: $gray-color;
        }
    }
}