//Hide lightbox elements on page load
.lightbox {
  display: none;
}

.gallery {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  
  label {
    cursor: pointer;
  }
  
  @include media-query($on-palm){
    display: block;
  }
}

.gallery-preview {
  display: block;
}

.gallery-trigger {
  display: none;
}
.gallery-image {
  display: block;
  @include rem-fallback(margin-bottom, $spacing-unit);
  @include rem-fallback(padding-left, $half-spacing);
  @include rem-fallback(padding-right, $half-spacing);
  
  label {
    text-align: center;
  }
  
  .close {
    display: none;
  }
}

.art {
  .portrait {
    max-width: 250px;
  }

  .landscape {
    max-width: 350px;
  }
}


@include media-query($on-palm){
  .art {
    .portrait,
    .landscape {
      max-width: 100%;
      max-height: 800vh;
    }
  }
}


/******************* Lightbox ***********************/

.gallery {
  
  input:checked ~ .lightbox {
    display: block;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: $lightbox-background;
    left: 0;
    
    h1 {
      margin-top: $half-spacing;
      color: #fff;
      text-align: center;
      margin-top: $half-spacing;
    }
    
    img {
      display: block;
      max-width: 80vw;
      margin: 60px auto 0;
      max-height: calc(80vh - 60px);
    }
    
  }

  input:checked ~ label {
    position: fixed;
    right: 15px;
    z-index: 1;
    text-align: right;

    .close {
      font-size: $spacing-unit;
      display: block;
      z-index: 1;
      color: #fff;
      position: fixed;
      top: $half-spacing;
      right: 0;
      margin-right: calc(10% - #{$half-spacing}); // Not sure about the variable syntax. Reference https://stackoverflow.com/a/20236515/1516372
      
    }
    
    .gallery-image {
      display: none;
    }
  }
}
